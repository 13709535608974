// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$breakpoint-classes: (small medium large);

// 3. The Grid
// -----------

$grid-row-width: rem-calc(1200);
$grid-column-count: 12;
$grid-column-responsive-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$block-grid-max: 8;

// 22. Menu
// --------

$menu-margin: 0;
$menu-margin-nested: 1rem;
$menu-item-padding: 0.7rem 1rem;
$menu-item-color-active: $white;
$menu-item-background-active: map-get($foundation-palette, primary);
$menu-icon-spacing: 0.25rem;