$color-gray-black: #333;
$color-yellow: #FDD027;
$color-yellow-mode: #FDD43C;
$color-transparent-yellow: rgba(253, 208, 39, 0.96);
$color-text: $color-gray-black;
$color-gray: #F5F5F5;
$color-gray-border: #979797;
$color-gray-hover: #757575;
$color-white: #fff;
$color-black: #000;
