.webexpo-overview .webexpo-overview-item .button-view {
	background: $color-yellow;
	border-radius: 2px;
	color:  #000;
}

.webexpo-overview .webexpo-overview-item .button-view:hover {
	background: $color-gray-black;
	color: #fff;
}

.webexpo-overview .button-fullscreen, .webexpo-sort .button-sort {
	border-radius: 2px;
}

.webexpo-sort .button-sort:hover, .webexpo-overview .button-fullscreen:hover {
    background-color: $color-gray-black;
	color: #fff;
}

.webexpo-overview .button-fullscreen:hover {
    background-color: $color-gray-hover;
    cursor: pointer;
}

.webexpo-overview .button-fullscreen {
	width: 25px;
	height: 25px;
	top: 3px;
}

.pic-webexpo-detail-view {
	.site-content.row {
        max-width: 100%;
    }
    .site-content .columns {
        padding-left: 0;
        padding-right: 0;
        float: none;
    }
}