.headerimg {
  position: relative;
}
 .headerimg .custom {
    background-position: 50% 50%;
    background-size: cover;
    min-height: 400px;
    min-width: 100%;
      @include breakpoint(medium){
      min-height: 700px;
  }
    .text-container {
      @include center;
      top: 57%;
      text-align: center;
      width: 15em;
      font-size: rem-calc(18);
      visibility: hidden;
    }
    p {
      display: inline;
      background-color: $color-yellow;
      padding: 5px;
      box-shadow: 10px 0 0 #FDD027, -10px 0 0 #FDD027;

    }
  }
  .search-container {
    position: absolute;
    z-index:100;
    bottom: 0;
    text-align: center;
    min-width: 100%;
  }
  .search-box {
    background: $color-transparent-yellow;
    height: 188px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: -188px;
    position: absolute; 
    @include breakpoint(medium){
      top: -180px;
    }
  }
  
.search-input {
    @include center;
    top: 40px;
    margin: 55px 0;
    height: 70px;
    width: 300px;
    padding: 10px;
    background-color: #fff;
}

.search-icon {
    background-image: url(../img/zoek-icon.png);
    background-repeat: no-repeat;
    background-position: 80%;
    height: 45px;
    width: 60px;
    background-color: #fff;
    border: none;
    position: relative;
    outline: none;
    margin: 70px 0 0 230px;
    z-index: 999;
}


.search-input {
    @include breakpoint(medium){
      width: 600px;
    }
}
.search-icon {
    @include breakpoint(medium){
      background-position: 98%;
      width: 50px;
      margin: 70px 0 0 533px;
    }
}
.headerimg .custom .text-container {
    @include breakpoint(medium){
      width: 31em;
      font-size: rem-calc(24);
      visibility: visible;
    }
}



