
.com_mediabank {
    .top-border {
        border-top: none;
        margin-bottom: 0;
    }
    .site-content.row {
        max-width: 100%;
    }
    .site-content .columns {
        padding-left: 0;
        padding-right: 0;
        float: none;
    }
    .mediabank-container {
        max-width: 100%;
    }
    .mediabank-search {
    	background: $color-yellow;
    }

    .mediabank-result-header {
        background: none;
        float: left;
        margin-bottom: 0;
        padding: 0;
        margin-left: 20px;
    }

    .mediabank-result-options {
        margin: 0 0 20px;
    }

    .mediabank-gallery-mode a{
    	color: $color-yellow-mode;
    }

    .mediabank-gallery-mode a:hover {
        color: $color-gray-hover;
    }
    .mediabank-gallery-mode .active a {
        color: $color-gray-black;
    }

    .mediabank-pagination
    .pagination-button-next {
    	background: $color-gray-black;
    }
    .mediabank-pagination
    .pagination-button-prev {
    	background: $color-gray-black;
    }

    .mediabank-pagination .pagination-button-next:hover {
        background:  $color-gray-hover;
        }
        .mediabank-pagination .pagination-button-prev:hover {
        background:  $color-gray-hover;
        }
    .mediabank-detail-panel-close:hover, .mediabank-button-back:hover {
        background: $color-gray-hover;
    }

    .mediabank-search .mediabank-search-submit {
        background-image: url(../img/zoek-icon.png);
        background-repeat: no-repeat;
        text-indent: -195px;
        background-color: #fff;
        height: 63px;
        margin: 3px 100px;
        height: 51px;
        width: 58px;
        outline: none;
        margin: 3px 5px;
        @include breakpoint(medium){
            margin: 3px 100px;
        }
    }

    .mediabank-search .mediabank-search-term {
    	height: 63px;
    }

    .mediabank-search .mediabank-search-submit:hover {
        background-image: url(../img/zoek-icon.png);
        background-repeat: no-repeat;
        background-color: #fff;
        outline: none;
    }

    .mediabank-search .mediabank-search-help {
        left: 0 !important;
        width: 50px;
        height: 50px;
        margin: 6.5px;
        padding: 4px;
        background: $color-yellow;
        color: $color-black;
        font-size: rem-calc(28);
    }

    .mediabank-search .mediabank-search-term {
        padding-left: 70px !important;
    }

    .mediabank-button-back, .mediabank-detail-menu, .mediabank-detail-panel-close {
        background: $color-gray-black;
    }
    .mediabank-detail-panel-close {
    	border-radius: 1px;
    }
    .openseadragon-canvas {
        background:  $color-gray-black !important;
    }

    .mediabank-detail-panels>section {
        line-height: 2;
    }

    .mediabank-result-options {
        border-bottom: 1px solid $color-gray-black;
    }

    .mediabank-gallery-mode .active {
        margin-top: -10px;
        padding: 10px;
        border: 1px solid  $color-gray-black;
        border-bottom: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .mediabank-filter-dropdown {
        background: $color-gray-black;
    }

    .mediabank-filter-list .mediabank-filter:hover {
        color: $color-gray-hover;
    }

    .mediabank-filter-list>li.active {
        color: $color-gray-hover;
    }

    .mediabank-filters-active .filter-active {
        background: $color-gray-black;
    }

    .pic-period-box input {
        color: #000;
    }

    .mediabank-filter-dropdown .mediabank-page-next, .mediabank-filter-dropdown .mediabank-page-prev{

        border-radius: 2px;
        background: #070606;

    }

    .mediabank-filter-links-list a:hover {
        color: $color-yellow;
    }

    slider div.bar .selection {
        background: $color-yellow;
    }

    .mediabank-mode-detail .mediabank-search .mediabank-search-fieldset {
      top: -12px;
    }

    a.download_lowres {
        cursor: pointer;
    }
}
