body {
	font-family: 'Asap', sans-serif;

}

.pic-icon-search:before {
    content: '';
}

a {
		text-decoration: none;
	  color: #FF5723;
}
.item-page {
    a:hover {
        text-decoration: underline;
        cursor: pointer;
    }

		h2, h3, h4, p {
			margin-bottom: 10px;
		}
}

.pic-load-previous-container .pic-load-previous {
    background: $color-gray-black;
}
.pic-load-previous-container .pic-load-previous:hover {
    background: $color-gray-hover;
}
.top-border {
    border-top: 10px $color-yellow solid;
    content: "";
    width: 100%;
    margin-bottom: 10px;
}
.hp-site-content {
	background: $color-gray;
	padding: 20px;
	max-width: 100%;

    @include breakpoint(large) {
        ul {
            width: 100% !important;
        }

        li {
            width: 33% !important;
			display: inline-block !important;
			padding: 0;
			margin: 0;
			height: 70px;
			padding-top: 50px;

			a{
				padding: 0 !important;
				margin: 0 !important;
				top: 0 !important;

				&:hover{
					text-decoration: underline;
				}
			}

			.icon-mediabank {
				&:before {
					display: none;
				}
			}
        }
    }
}

.border-top {
    border-top: 2px solid $color-gray-border;
    position: relative;
    top: 15px;
    padding-top: 15px;
    @include breakpoint(medium){
        top: 0;
        left: -44px;
    }
}
.text-center-bronnen {
		text-align: center;
    margin: 2px 0;
		  @include breakpoint(medium){
  			margin: -14px 0;
			}
}

.exposition-title-container h3 {
    background-color: $color-yellow;
    padding: 0;
    z-index: 9999;
    position: relative;
    text-align: center;
    color: #000;
    line-height: 1.6;
    right: 0;
    top: 250px;
    width: 100%;
    margin: 0 auto;
    font-weight: 300;
		-webkit-transition: color 0.4s ease, background-color 0.4s ease;
		-moz-transition: color 0.4s ease, background-color 0.4s ease;
		transition: color 0.4s ease, background-color 0.4s ease;

}

.exposition-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 250px;
		margin-bottom: 20px;
}

 h3 a {
	 	text-decoration: none !important;
}

.moduletable {
		text-align: center;
	  line-height: 4;
}
.icon-newspaper:hover, .icon-mediabank:hover {
    text-decoration: underline;
}

 .expositions-thumbnails a:hover  h3{
    background-color: rgba(51, 51, 51, 0.93);
    color: #fff;
}
.icon-mediabank, .icon-newspaper {
    @include  breakpoint(medium){
        height: 9em;
    }
}

.icon-mediabank {

    @include breakpoint(medium){
        margin: -197px 0 0 0;
				&::before {
					content: url(../img/left-bar.svg);
			    position: relative;
			    bottom: -75px;
			    left: -27px;
				}
    }
}
.icon-newspaper{

    @include breakpoint(medium){
        margin-top: 40px;
    }
}

.border-top {
    @include breakpoint(medium){
    border-top: none;
    padding-top: none;
  }
}
