.topbar {

    &__header {
      position: relative;
      top: 10px;
    }
    .menu {
      height: 0px;
    }

  .menu {
      background: $color-white;
      position: absolute;
      width: 100vw;
      height: 100vh;
      z-index: 9999;
      left: 0;
      top: -5px;

      @include breakpoint(large){
        position: static;
        height: 100px !important;
        width: auto;
        background: transparent;
      }
  }

  .menu > li a {
    text-decoration: none;
    color: black;
    cursor: pointer;

  }

  .menu > li > a {
  &:visited { background: $color-yellow; }
  &:hover { background: $color-gray-black; color: #fff;}
  &:active { background: $color-yellow;}
  }

  .menu-toggle {
    position: fixed;
    top: 11px;
    /* left: 0; */
    right: 12px;
    z-index: 10001;
    cursor: pointer;
  }

  .menu-burger {
    width: 20px;
    height: 3px;
    background: $color-gray-black;
    border: none;
    position: relative;
    margin-top: 4px;

  }

  .facebook {
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    background-image: url('../img/facebook.svg');
    width: 32px;
    height: 32px;
    margin-left: 15px;

    @include breakpoint(large) {
      position: absolute;
      right: 15px;
      top: 5px;
      margin-left: 0px;
    }
  }

  .item-112 a:hover{
    background-image: url('../img/facebook.svg') !important;
    background-repeat: no-repeat !important;

  }

  .menu.vertical > li {
    background: #fff;
  }

  .menu.vertical > li {
    @include breakpoint(large){
    background: none;
  }
}
  .facebook {
    @include breakpoint(large) {
      margin-left: 17.9em;
    }

  }
}

.menu-toggle {
  @include breakpoint(large){
    visibility: hidden;
  }
}

.topbar .menu-toggle {
  @include breakpoint(medium){
    position: fixed;
    top: 10px;
    right: 17px;
  }
}
